import { createContext, useEffect, useReducer } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from 'src/config';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import { PATH_DASHBOARD } from '../router/paths';
import { setSession } from '../utils/jwt';

let auth0Client = null;

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  organization: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, organization } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      organization
    };
  },
  LOGIN: (state, action) => {
    const { user, organization } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      organization
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    organization: null
  })
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Auth0',
  logInWith: () => Promise.resolve(),
  loginWithRedirect: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const navigate = useNavigate();

  const buildUserObject = (user, joltUser) => {
    if (joltUser?._id && !joltUser?.emailVerified) {
      axios.post(`/api/users`, {
        users: [{
          _id: joltUser._id,
          status: 'active',
          emailVerified: true
        }]
      });
    }
    return {
      id: user.sub,
      avatar: user.picture,
      email: user.email,
      name: user.name,
      emailVerified: user.email_verified,
      firstName: user.given_name,
      lastName: user.family_name,
      role: user.role,
      location: user.location,
      username: user.username,
      nickname: user.nickname,
      joltPermissionsConnections: user['jolt-permissions/connections'],
      joltPermissionsRoles: user['jolt-permissions/roles'],
      ...joltUser
    };
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          cacheLocation: 'localstorage',
          ...auth0Config
        });

        const query = window.location.search;
        if (query.includes('code=') && query.includes('state=')) {
          try {
            await auth0Client.handleRedirectCallback();
          } catch (e) {
            console.error('handleRedirectCallback error', e);
          }
          window.history.replaceState({}, document.title, '/');
        } else {
          await auth0Client.checkSession();
        }

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const pathRedirect = window.localStorage.getItem('loginUrl');

          const user = await auth0Client.getUser();

          const accessToken = await auth0Client.getTokenSilently();

          const idToken = await auth0Client.getIdTokenClaims();

          setSession(accessToken, idToken.__raw);

          const accountResponse = await axios.get(`/api/organizations`, {
            params: {
              id: user.sub
            }
          });

          const {
            data: { organizations = [], users = [] }
          } = accountResponse;
          const organization = organizations[0] || null;
          const joltUser = users[0] || null;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: buildUserObject(user, joltUser),
              organization
            }
          });

          if (pathRedirect && organization) {
            window.localStorage.removeItem('loginUrl');
            navigate(pathRedirect);
          } else if (organization) {
            navigate(PATH_DASHBOARD.main);
          } else if (pathRedirect === '/registration/partner') {
            navigate(PATH_DASHBOARD.register.partner);
          } else {
            navigate(PATH_DASHBOARD.register.root);
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
              organization: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            organization: null
          }
        });
      }
    };

    initialize();
  }, []);

  const loginWithRedirect = async (sendTo) => {
    window.localStorage.setItem('loginUrl', sendTo || window.location.pathname);

    await auth0Client.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.href
      }
    });
  };

  const loginWithPopup = async (options) => {
    auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.sub,
            jobtitle: 'Lead Developer',
            avatar: user.picture,
            email: user.email,
            name: user.name,
            role: user.role,
            location: user.location,
            username: user.username,
            posts: user.posts,
            coverImg: user.coverImg,
            followers: user.followers,
            description: user.description
          }
        }
      });
    }
  };

  const logout = () => {
    auth0Client.logout();
    dispatch({
      type: 'LOGOUT'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithPopup,
        loginWithRedirect,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
