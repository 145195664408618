import { createSlice } from '@reduxjs/toolkit';
// utils
import { find } from 'lodash';
import axios from '../utils/axios';
//
import { dispatch } from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  workspacesCollected: false,
  activeWorkspace: null,
  error: null,
  workspaces: []
};

const slice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    // START LOADING
    startLoadingWorkspaces(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET WORKSPACES
    getWorkspacesSuccess(state, action) {
      state.isLoading = false;
      state.workspacesCollected = true;
      const { workspaces, activeId, organization } = action.payload;
      state.workspaces = workspaces;
      const activeWorkspace = activeId ? find(workspaces, { _id: activeId, organization }) : null;

      if (activeWorkspace) {
        state.activeWorkspace = activeWorkspace;
      } else {
        state.activeWorkspace = workspaces[0];
      }
    },

    // GET WORKSPACES
    setActiveWorkspaceSuccess(state, action) {
      state.isLoading = false;
      state.activeWorkspace = action.payload;
    }
  }
});


// Reducer
export const reducer = slice.reducer;

// ----------------------------------------------------------------------

export function getWorkspaces(organization, activeId) {
  return async () => {
    dispatch(slice.actions.startLoadingWorkspaces());
    try {
      const response = await axios.get('/api/workspaces', {
        params: { organization }
      });
      const { workspaces } = response.data;
      dispatch(slice.actions.getWorkspacesSuccess({ workspaces, activeId, organization }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setActiveWorkspace(workspace) {
  return async () => {
    dispatch(slice.actions.startLoadingWorkspaces());
    try {
      dispatch(slice.actions.setActiveWorkspaceSuccess(workspace));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
